import Article from '@/components/Article';
import { Post } from '@/types/post';

type Props = {
  posts: Post[];
};

export default function GridView({ posts }: Props) {
  return (
    <div className="max-w-full mx-auto">
      <ul
        role="list"
        className="grid grid-cols-1 gap-4 sm:gap-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4"
      >
        {posts.map((post) => (
          <li
            key={post.title}
            className="col-span-1 border-b divide-y divide-gray-200 dark:divide-gray-800 border-slate-100 dark:border-slate-800 sm:border-none"
          >
            <Article post={post} />
          </li>
        ))}
      </ul>
    </div>
  );
}
