import cn from 'classnames';
import CustomLink from '@/modules/article/Link';
import { Tag } from '@/types';

type Props = {
	tags: Tag[];
};

export default function TagLists({ tags }: Props) {
	return (
		<div className="hidden gap-2 overflow-x-auto sm:min-w-[300px] sm:gap-2 lg:flex">
			{tags?.map((tag) => (
				<CustomLink
					href={`/${tag.slug}`}
					className={cn(
						'inline-block cursor-pointer whitespace-nowrap rounded-lg border bg-slate-200 px-2 py-1 text-center align-baseline text-sm font-semibold leading-none text-slate-700 hover:opacity-80 dark:border-slate-600 dark:bg-transparent dark:text-gray-300',
					)}
					key={tag.slug}
				>
					{tag.short_title}
				</CustomLink>
			))}
		</div>
	);
}
