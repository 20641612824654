import TagLists from '@/components/TagList';
import { Tag } from '@/types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { AiOutlineAppstore, AiOutlineUnorderedList } from 'react-icons/ai';
import CustomLink from '../article/Link';

type Props = {
	defaultActive?: string;
	onChangeContentViewMode: (val: 'grid' | 'list') => void;
	tag: Tag;
	subTags: Tag[];
};

export const SectionHeader: FC<Props> = ({ tag, subTags, defaultActive, onChangeContentViewMode }) => {
	const [viewMode, setViewMode] = useState(defaultActive);
	const router = useRouter()

	const changeContentViewMode = (val) => {
		setViewMode(val);
		onChangeContentViewMode(val);
	};

	return (
		<div className="mb-6">
			<div className="flex items-center justify-between border-slate-300">
				<div className="items-center gap-4 align-middle sm:flex flex-col-1">
					<CustomLink className='leading-none' href={`/${tag.slug}`}>
						<h1 className="mb-4 text-2xl font-bold leading-none text-left hover:opacity-80 dark:text-gray-300 sm:text-3xl sm:mb-0">
							{tag?.title || 'Analysis'}
						</h1>
					</CustomLink>
					<TagLists tags={subTags} />
				</div>
				<div className="hidden items-center justify-end gap-1 py-2.5 sm:flex sm:min-w-[300px] sm:gap-4">
					<button onClick={() => changeContentViewMode('grid')}>
						<AiOutlineAppstore
							size={26}
							className={clsx('text-black dark:text-gray-300', {
								'text-[#3d8bf6] dark:text-[#3d8bf6]': viewMode === 'grid',
							})}
						/>
					</button>

					<button onClick={() => changeContentViewMode('list')}>
						<AiOutlineUnorderedList
							size={22}
							className={clsx('text-black dark:text-gray-300', {
								'text-[#3d8bf6] dark:text-[#3d8bf6]': viewMode === 'list',
							})}
						/>
					</button>
				</div>
			</div>
		</div>
	);
};
