import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
require('dayjs/locale/vi');

export const formatDate = (date: string, locale = 'en-US', formatOptions: Intl.DateTimeFormatOptions = {}) => {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		...formatOptions,
	};
	const now = new Date(date).toLocaleDateString(locale, options);

	return now;
};

export const getDaysDiffBetweenDates = (dateInitial: Date, dateFinal: Date) =>
	(dateFinal.getTime() - dateInitial.getTime()) / (1000 * 3600 * 24);

export const getDaysDiffBetweenDatesString = (dateInitial: Date, dateFinal: Date) => {
	const days = getDaysDiffBetweenDates(dateInitial, dateFinal);
	return days > 0 ? `${days} days` : 'Today';
};

export const getHoursDiffBetweenDates = (dateInitial: Date, dateFinal: Date) =>
	(dateFinal.getTime() - dateInitial.getTime()) / (1000 * 3600);

export const getHoursDiffBetweenDatesString = (dateInitial: Date = new Date(), dateFinal: Date = new Date()) => {
	const hours = Math.round(getHoursDiffBetweenDates(dateInitial, dateFinal));
	return hours > 0 ? `${hours} hours ago` : 'Now';
};

export const getDiffFromNow = (date: Date | string, withoutSuffix = false) => {
	return dayjs(date).fromNow(withoutSuffix);
};

export const formatArticleTime = (input: Dayjs | string, locale?) => {
	const day = typeof input === 'string' ? dayjs(input) : input;
	const today = dayjs();
	if (today.diff(day, 'hour') < 24) {
		return day.fromNow();
	} else if (today.diff(day, 'day') < 4) {
		return day.fromNow();
	}

	return formatDate(input as string, locale);
};

export { dayjs };
