import { REPORT_PLAN, REPORT_PLANS } from '@/lib/constants';
import { Post } from '@/types';
import { FaLock } from 'react-icons/fa';

interface ContentTagProps {
	tickets: Post['tickets'];
}

const ContentTag: React.FC<ContentTagProps> = ({ tickets = [] }) => {
	let label = '';

	REPORT_PLANS.some((planId) => {
		if (tickets.findIndex((t) => t.id === planId) !== -1) {
			label = REPORT_PLAN[planId];
			return true;
		}

		return false;
	});

	return (
		<div className="absolute  bottom-0 right-0 mb-[0.5rem] mr-[0.5rem] flex items-center gap-2 rounded-md bg-[#16181d] px-2 py-2	xl:mb-[0.25rem]	xl:mr-[0.25rem]">
			<FaLock color="#f59e0b" scale={0.1} />
			<div className="text-xs font-semibold uppercase text-amber-500">{label}</div>
		</div>
	);
};

export default ContentTag;
