import cn from 'classnames';

export const Heading = ({ children, className = '' }) => {
  return <h2 className={cn("text-[22px] leading-7", className)}>{children}</h2>;
};

export const Paragraph = ({ children, className = '' }) => {
  return <p className={cn("text-[14px] leading-[21px]", className)}>{children}</p>;
};

export const PageTitle = ({ children }) => {
  return <h1 className="text-[34px] leading-[135%] font-semibold">{children}</h1>;
};

/**
 * Blog Style
 */

export const BlogCardTitle = ({ children, className = '' }) => {
  return (
    <h4 className={cn("text-[17px] font-semibold leading-[1.3] hover:underline", className)}>{children}</h4>
  );
};

export const BlogTag = ({ children }) => {
  return (
    <div className="text-[#676f83] dark:text-[#8fb3c4] bg-[#f0f6f8] dark:bg-[#16181d] rounded-lg px-[6px] py-[3px]">
      <span className="text-[0.849rem] font-semibold leading-[1.25] whitespace-nowrap">
        {children}
      </span>
    </div>
  );
};

export const BlogTitle = ({ children }) => {
  return <h1 className="text-[34px] leading-[135%] font-semibold">{children}</h1>;
};

export const BlogAuthor = ({ children }) => {
  return <span className="text-[1rem] leading-[1.4]">{children}</span>;
};

export const BlogDate = ({ children, className }) => {
  return <span className={cn("text-[0.875rem] leading-[1.5]", className)}>{children}</span>;
};

