import type { GetStaticProps, InferGetServerSidePropsType } from 'next';

import { getServerSideTranslations } from '@/lib/i18n';
import { BlogPage } from '@/modules/blog/BlogPage';
import { getPostList } from '@/services/post/list';
import { getTagList } from '@/services/tag';

export default function Home({
  sections
}: InferGetServerSidePropsType<GetStaticProps>) {
  return <BlogPage sections={sections} />;
}

const pageSize = 8;

const getBlogPosts = async ({ locale, category = '', tags = '' }) => {
  try {
    return await getPostList({ category: category, pageSize: pageSize, locale, tags })
  } catch (e) {
    return {
      posts: [],
      error: e
    }
  }
};

export async function getStaticProps(context: { locale: string }) {
  const { locale } = context;
  
  const inlinedTranslations = await getServerSideTranslations(
    locale
  );

  const { tags } = await getTagList();
  const rootTags = tags.filter(t => t.rank === 1);

  const postSections = await Promise.all(
    rootTags.map(async rootTag => {
      const subTags = tags.filter(t => t.type ===  rootTag.slug);
      const { posts } = await getBlogPosts({ locale, category: 'dynamic', tags: [rootTag, ...subTags].map(s => s.slug).join(',') });
      return {
        tag: rootTag,
        sub_tags: subTags,
        posts: posts.slice(0, pageSize),
      };
    })
  )
 
  const length = postSections.map(({posts}) => posts.length).reduce((a, b) => a + b, 0);

  return {
    props: {
      locale,
      ...inlinedTranslations,
      sections: postSections,
    },
    revalidate: length > 0 ? 300 : 1,
  };
}
