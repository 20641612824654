// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  title: string;
  description?: string;
};

export default function EmptyContent({ title, description }: Props) {
  return (
    <main className="flex flex-col w-full px-4 mx-auto mt-header-mobile max-w-8xl desktop:mt-header-desktop desktop:px-8 min-h-content grow">
      <div className="py-16 m-auto text-center desktop:py-32 shrink-0">
        <p className="mt-16 text-2xl font-medium tracking-tight opacity-70 md:text-lg desktop:tracking-tight text-neutral-900 dark:text-neutral-100">
          {title}
        </p>

        {description && (
          <p className="mt-2 text-base text-neutral-50">{description}</p>
        )}
      </div>
    </main>
  );
}
