import { Banner1 } from '@/components/ads/Banner1';
import EmptyContent from '@/components/EmptyContent';
import Container from '@/components/layouts/Container';
import { DEFAULT_META_TAGS } from '@/config/metaData';
import { Tag } from '@/types';

import { Post } from '@/types/post';
import { useTranslation } from 'next-i18next';
import { FC, useMemo, useState } from 'react';
import CustomLink from '../article/Link';
import GridView from './GridView';
import { SectionHeader } from './SectionHeader';
import TableView from './TableView';

type Props = {
	sections: {
		tag: Tag;
		posts: Post[];
		sub_tags: Tag[];
	}[];
};

export const BlogPage: FC<Props> = ({ sections }) => {
	const { t } = useTranslation(['common']);
	const [contentLayout, setContentLayout] = useState('grid');

	const onChangeViewLayout = (val) => {
		setContentLayout(val);
	};

	const onchainAnalytics = useMemo(() => {
		if (sections) {
			return sections.find((s) => s.tag.slug === 'on-chain-analysis');
		}
	}, [sections]);

	const onchainKnowledge = useMemo(() => {
		if (sections) {
			return sections.find((s) => s.tag.slug === 'on-chain-knowledge');
		}
	}, [sections]);

	return (
		<Container title={t('common:app.homeTitle') as string} additionalLinkTags={DEFAULT_META_TAGS}>
			{/* <Banner1 /> */}

			<div className="px-3 py-7 sm:px-7 sm:py-7">
				<div className="grid grid-cols-1 gap-6 divide-y dark:divide-slate-800">
					{onchainAnalytics && (
						<div>
							<SectionHeader
								tag={onchainAnalytics.tag}
								subTags={onchainAnalytics.sub_tags}
								onChangeContentViewMode={onChangeViewLayout}
							/>

							{onchainAnalytics?.posts?.length === 0 ? (
								<EmptyContent title={t('common:empty.title')} />
							) : (
								<>
									{contentLayout === 'list' ? (
										<TableView posts={onchainAnalytics.posts} />
									) : (
										<GridView posts={onchainAnalytics.posts} />
									)}

									<div className="mt-6 mb-2 w-full text-center">
										<CustomLink href="/on-chain-analysis">
											<button className="inline-block min-w-[140px] cursor-pointer whitespace-nowrap rounded-lg bg-slate-100 px-2 py-2.5 text-center align-baseline text-sm font-semibold leading-none text-slate-500 hover:opacity-80 dark:border-slate-600 dark:bg-slate-700 dark:text-gray-300">
												{t('common:content.view_all')}
											</button>
										</CustomLink>
									</div>
								</>
							)}
						</div>
					)}

					{onchainKnowledge && (
						<div className="pt-8">
							<SectionHeader
								tag={onchainKnowledge.tag}
								subTags={onchainKnowledge.sub_tags}
								onChangeContentViewMode={onChangeViewLayout}
							/>

							{onchainKnowledge?.posts?.length === 0 ? (
								<EmptyContent title={t('common:empty.title')} />
							) : (
								<>
									{contentLayout === 'list' ? (
										<TableView posts={onchainKnowledge.posts} />
									) : (
										<GridView posts={onchainKnowledge.posts} />
									)}

									<div className="mt-6 mb-2 w-full text-center">
										<CustomLink href="/on-chain-knowledge">
											<button className="inline-block min-w-[140px] cursor-pointer whitespace-nowrap rounded-lg bg-slate-100 px-2 py-2.5 text-center align-baseline text-sm font-semibold leading-none text-slate-500 hover:opacity-80 dark:border-slate-600 dark:bg-slate-700 dark:text-gray-300">
												{t('common:content.view_all')}
											</button>
										</CustomLink>
									</div>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</Container>
	);
};
